
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
export default function CategoryCard({ categories, cat, setCat }) {

    const navigate = useNavigate()
    const settings = useSelector((state) => state.settings.data);


    const image = categories?.image ? categories?.image : `${settings.image_url}/uploads/${settings.logo}`
    // 
    return (
        <>
            <div className={`mb-3 rounded col-6 col-sm-3 col-md-2 cursor-pointer p-1 `}
                onClick={() => { navigate(`/products/${categories.id}`); setCat(categories.name) }}>
                <img src={image} alt="" className='w-full rounded img-aspect' />
                <div className='p-1 text-black bg-white border-t-8 border-red-800 rounded-b '>
                    <p>{categories.name}  </p>
                </div>
            </div>
        </>

    )

}