
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  data: 0,
};


export const fetchTotalPrice = createAsyncThunk('totalPrice/fetchTotalPrice', async (_, thunkAPI) => {
  try {
    const apiBase = thunkAPI.getState().apiBase.value;
    const userId = localStorage.getItem('menu_user_id')
    const response = await axios.get(`${apiBase}get-total/${userId}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const totalPriceSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTotalPrice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTotalPrice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchTotalPrice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
  },
});

export default totalPriceSlice.reducer;