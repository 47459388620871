// // store/cartSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};
// 

export const fetchCartContent = createAsyncThunk('cart/fetchCartContent', async (_, thunkAPI) => {
  try {
    const apiBase = thunkAPI.getState().apiBase.value;
    const userId = localStorage.getItem('menu_user_id')
    const response = await axios.get(`${apiBase}get-cart-content?user_id=${userId}`);
    return response.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeProduct = createAsyncThunk('cart/removeProduct', async (id, thunkAPI) => {
  try {
    const apiBase = thunkAPI.getState().apiBase.value;
    const userId = localStorage.getItem('menu_user_id')
    await axios.get(`${apiBase}remove-product/${id}?user_id=${userId}`);
    return id;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartContent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCartContent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchCartContent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(removeProduct.fulfilled, (state, action) => {
        state.data = state.data.filter(item => item.product.id !== action.payload);
      })
  },
});

export default cartSlice.reducer;
