import ExternalLink from '../shared/externallink';
import './layouts.css'
import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp, FaMapMarked } from "react-icons/fa";

export default function Footer({ data }) {

    const facebook = <FaFacebookF size={25} />
    const facebookLink = `${data.facebook}`
    const instagram = <FaInstagram size={25} />
    const instagramLink = `${data.instagram}`
    return (
        <footer className="pt-7 pb-16">
            <div className="justify-center row">
                <div className="p-1 mx-2 col-1">
                    <ExternalLink url={facebookLink} label={facebook} />
                </div>
                <div className="p-1 mx-2 col-1">
                    <ExternalLink url={instagramLink} label={instagram} />
                </div>
                <div className="p-1 mx-2 col-1">
                    <a href={`https://wa.me/${data.whatsapp}`} target="_blank" rel="noopener noreferrer" className='text-black'>
                        <FaWhatsapp size={25} />
                    </a>
                </div>
                <div className="p-1 mx-2 col-1"><a href={data.address} target="_blank" rel="noopener noreferrer" className='text-black'><FaMapMarked size={25} /></a></div>
            </div>
            <br />
            <div className="text-center text-gray-500 row">
                <p> phone 1:<a href={`https://wa.me/${data.phone_number_1}`} className=' link-color'>{data.phone_number_1}</a></p>
            </div>

            <div className="text-center text-gray-500 row">
                <p> phone 2:<a href={`https://wa.me/${data.phone_number_2}`} className=' link-color'>{data.phone_number_2}</a></p>
            </div>
            <br />
            <br />
            <div className="mt-3 text-center row">
                <p className='p-0 text-xs'> Proudly Developed By <strong>
                    <a href="https://www.sherifshalaby.tech" className='text-black text-xs' target="_blank" rel="noopener noreferrer">
                        sherifshalaby.tech
                    </a>
                </strong>
                </p>
                <p>tele:<a href={`https://wa.me/00905386531059`} className='text-xs link-color'>00905386531059</a></p>
            </div>
        </footer>
    )
}