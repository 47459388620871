import React from "react";
import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Translationen from "./local/en.json";
import Translationar from "./local/ar.json";
import Translationhi from "./local/hi.json";
import Translationtr from "./local/tr.json";
import Translationur from "./local/ur.json";
import Translationnl from "./local/nl.json";
import Translationfr from "./local/fr.json";
import Translationfa from "./local/fa.json";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: Translationen
      },
      ar: {
        translation: Translationar
      },
      fa: {
        translation: Translationfa
      },
      fr: {
        translation: Translationfr
      },
      hi: {
        translation: Translationhi
      },
      nl: {
        translation: Translationnl
      },
      tr: {
        translation: Translationtr
      },
      ur: {
        translation: Translationur
      }
    },
    lng: localStorage.getItem('menu_lang') || 'tr', // if you're using a language detector, do not define the lng option
    // fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: {
      useSuspense: false
    }
  });

export default i18n
