import { useEffect, useState } from "react";
import bg from "../../assets/img/pexels-pixabay-326333.jpg"
import axios from "axios";
import { useSelector } from 'react-redux';
import { useParams } from "react-router";
import ProductCard from "../../components/shared/productCard";

export default function Products({ setCat, categories }) {
    const apiBase = useSelector((state) => state.apiBase.value);
    const [products, setproducts] = useState([])
    const idparam = useParams().id

    const cat = categories.filter((cat) => cat.id == idparam)

    useEffect(() => {
        axios.get(`${apiBase}get-products-list/${idparam}`).then((response) => {
            setproducts(response?.data.data);
        }).catch(console.error())


    }, [apiBase, idparam]);

    useEffect(() => {
        setCat(cat[0]?.name)
    }, [cat[0]?.name])

    return (
        <>
            <div style={{ backgroundImage: `url(${bg})`, paddingBottom: '15vw', backgroundRepeat: 'no-repeat', width: '100%', height: 'fit-content', padding: '3vw', color: 'blue', backgroundColor: 'black' }} >
                <div className="justify-center mt-5 row">
                    {products?.map(products => (
                        <ProductCard key={products.id} data={products} />
                    ))}
                </div>
            </div>
        </>
    )
}