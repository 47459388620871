import React, { useState, useTransition } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CategorySelect = ({ categories, onCategorySelect, cat, setCat }) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [t, i18n] = useTranslation()


  const toggleList = (cat) => {
    setIsListOpen(!isListOpen);

    setCat(cat)
  };

  return (
    <div className="inline-block m-auto text-center dd-wrapper bg-slate-50 rounded-lg">
      <button
        type="button"
        className="flex justify-center items-center w-full px-2 py-1 text-center border rounded bg-slate-300 dd-header-none"
        onClick={() => setIsListOpen(!isListOpen)}
      >
        <div className="dd-header-title">{cat}</div>
        {isListOpen ? (
          <FontAwesomeIcon icon={faAngleUp} className="mt-1 ms-1" />
        ) : (
          <FontAwesomeIcon icon={faAngleDown} className="mt-1 ms-1" />
        )}
      </button>
      {isListOpen && (
        <div role="list" className="mt-0 dd-list ">
          <button
            type="button"
            className="w-full my-1 border-b bg-white rounded opacity-70 hover:opacity-100 dd-list-item"
            onClick={() => { onCategorySelect("/"); toggleList(t("categories")) }}
          >
            {t("categories")}
          </button>
          {categories.map((item) => (
            <Link
              to={`/products/${item.id}`}
              type="button"
              className="w-full my-1 bg-white rounded opacity-70 hover:opacity-100 dd-list-item border-b text-gray-950 no-underline block"
              key={item.id}
              onClick={() => toggleList(item?.name)}
            >
              {item?.name}
              {item.selected && <FontAwesomeIcon icon={faAngleDown} size="5x" />}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategorySelect;

