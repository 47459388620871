
import i18n from "../../i18n";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const lang = i18n.language

const initialState = {
  value: `https://api.burgerle.com/${lang}/api/`
};

export const updateApiBase = createAsyncThunk(
  'apiBase/update',
  async (lang) => {
    const apiBase = `https://api.burgerle.com/${lang}/api/`;
    return apiBase;
  }
);

const apiBaseSlice = createSlice({
  name: 'apiBase',
  initialState,
  reducers: {
    setApiBase: (state, action) => {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateApiBase.fulfilled, (state, action) => {
      state.value = action.payload;
    });
  },
});

export const { setApiBase } = apiBaseSlice.actions;

export const selectApiBase = (state) => state.apiBase.value;

export default apiBaseSlice.reducer;



