
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    data: {},  
  };


  export const fetchSettings = createAsyncThunk('settings/fetchSettings', async (_, thunkAPI) => {
    try {
      const apiBase = thunkAPI.getState().apiBase.value;
      const response = await axios.get(`${apiBase}get-settings`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });

  const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchSettings.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchSettings.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.data = action.payload;
        })
        .addCase(fetchSettings.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        })
    },
  });
  
  export default settingsSlice.reducer;