import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {}
};

const orderDetailsice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    setorderDetails: (state, action) => {
      state.value = action.payload;
    },
  },
  extraReducers: {

  }

});
export const { setorderDetails } = orderDetailsice.actions;
export default orderDetailsice.reducer