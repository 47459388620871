import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { fetchCartContent } from "../../redux/slice/cart";
import { fetchTotalPrice } from "../../redux/slice/totalPrice";
import Swal from "sweetalert2";
export default function ProductCard({ data }) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  // 
  const dispatch = useDispatch();

  const apiBase = useSelector((state) => state.apiBase.value);
  const settings = useSelector((state) => state.settings.data);
  const [id, setId] = useState(data.variations ? data.variations[0].id : data.product_class_id);

  const image = data?.image ? data?.image : `${settings.image_url}/uploads/${settings.logo}`

  // const  handleAddtocart =async(id)=>{
  //    await axios.get(`${apiBase}add-to-cart/${id}`)
  //    dispatch(fetchCartContent())
  //    .then((response) => {
  //         setmessage(response.data.message)
  //     }).then(()=>setshow(true)).catch(console.error())
  // }

  // useEffect(() => {
  //   handleId();
  // }, [data]);
  // const handleId = () => {
  //   setId(data.variations ? data.variations[0].id : data.product_class_id);
  // };

  const handleAddtocart = async (id) => {
    try {
      const userId = localStorage.getItem('menu_user_id')
      await axios.get(`${apiBase}add-to-cart/${id}?user_id=${userId}`);
      dispatch(fetchCartContent());
      dispatch(fetchTotalPrice());

      Swal.fire({
        title: `${data.name}`,
        text: `${t("added to cart")}`,
        icon: 'success',
        showConfirmButton: false,
        timer: 1000
      })
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className={`mb-24 rounded col-6 col-sm-3 col-md-2 cursor-pointer`}>
        <div className="w-full aspect-square">
          <img
            src={image}
            alt=""
            className="w-full h-full rounded-t border-1 border-white border-b-0"
            onClick={() => navigate(`/productdetails/${data.id}`)}
          />
        </div>
        <div className="px-2 py-1 text-black bg-white border-t-8 border-red-800 rounded-b ">
          <p className="flex justify-between p-0 text-sm sm:text-tiny">
            {data.name}

            {data.variations?.length > 1 &&
              // (
              <button
                type="button"
                className=""
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1"
              >
                <FontAwesomeIcon icon={faList} />
              </button>
              // ) : (handleId())

            }
          </p>
          <p className="flex justify-between p-0 m-0">
            <button
              className="px-1 text-white bg-red-800 rounded-full"
              onClick={() => handleAddtocart(id)}
            >
              {" "}
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <div>
              <span className=" me-1">{data?.sell_price?.substring(0, data?.sell_price?.length - 2)}</span>
              {settings.currency_symbol}
            </div>
          </p>
        </div>
      </div>

      <div
        className="modal text-black fade"
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("variations")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="font-semibold col-6">{t("size")}</div>
                  <div className="font-semibold col-6">{t("name")}</div>
                </div>
                {data.variations?.map((item) => (
                  <div className="my-4 row">
                    <div className="font-semibold text-gray-700 col-4">
                      {item.size.name}
                    </div>
                    <div className="col-4">{item.name}</div>
                    <div className="col-2"></div>
                    <button
                      className="px-1 text-white bg-red-800 rounded-full col-2"
                      onClick={() => handleAddtocart(item.id)}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
