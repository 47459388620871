import arabic from "../../assets/img/arabic.jpg"
import ur from "../../assets/img/Flag_of_Pakistan.svg.png"
import CategorySelect from "./selectCategory"
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { updateApiBase } from '../../redux/slice/api';
import { useNavigate } from "react-router";
import Flag from "@weston/react-world-flags";


export default function Header({ categories, photos, cat, setCat }) {
  const [t, i18n] = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch();

  const firstImg = `${photos.path_slider}/uploads/${photos.home_background_image}`
  const secondtImg = `${photos.path_slider}/uploads/${photos.home_background_image2}`
  const ThirdtImg = `${photos.path_slider}/uploads/${photos.home_background_image3}`
  const handleLanguageChange = async (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("menu_lang", lang)
    await dispatch(updateApiBase(lang)); // Dispatch the createAsyncThunk action
  };

  const handleNavigation = (path) => {
    navigate(path)
  };
  // 
  return (
    <header className="fixed w-full md:relative">
      <div id="carouselExampleIndicators" className="border-b-8 border-red-900 carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={firstImg} className="w-full d-block img-slider w-100 bh-control" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={secondtImg} className="w-full d-block img-slider w-100 bh-control " alt="..." />
          </div>
          <div className="carousel-item">
            <img src={ThirdtImg} className="w-full d-block img-slider w-100 bh-control" alt="..." />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <nav className="absolute justify-center w-full text-center bg-transparent border-t-8 border-black h-fit align-center sm:p-4">
        <div className="absolute left-0 inline-block m-0 shadow-lg me-0 dropdown w-fit">
          <button className="p-1 text-xs bg-white dropdown-toggle sm:p-8 rounded-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {t("languages")}
          </button>
          <ul className="dropdown-menu">


            <li className="w-full">
              <button className="text-center btn ps-2 flex items-center w-full" onClick={() => { handleLanguageChange('ar') }}>
                <span>
                  ar
                </span>
                <img src={arabic} alt="ar" className="w-8 h-6 rounded inline ms-2" />
              </button>
            </li>



            <li className="w-full">
              <button className="text-center btn ps-2 flex flex-row items-center w-full justify-between" onClick={() => { handleLanguageChange('en') }}>
                <span>
                  en
                </span>
                <span className="w-5 inline-block ms-3">
                  <Flag code="us" height={15} width={20} />
                </span>
              </button>
            </li>



            <li className="w-full">
              <button className="text-center btn ps-2 flex flex-row items-center w-full justify-between" onClick={() => { handleLanguageChange('fr') }}>
                <span>
                  fr
                </span>
                <span className="w-5 inline-block ms-3">
                  <Flag code="fr" height={15} width={20} />
                </span>
              </button>
            </li>



            <li className="w-full">
              <button className="text-center btn ps-2 flex flex-row items-center w-full justify-between" onClick={() => { handleLanguageChange('nl') }}>
                <span>
                  nl
                </span>
                <span className="w-5 inline-block ms-3">
                  <Flag code="nl" height={15} width={20} />
                </span>
              </button>
            </li>


            <li className="w-full">
              <button className="text-center btn ps-2 flex flex-row items-center w-full justify-between" onClick={() => { handleLanguageChange('tr') }}>
                <span>
                  tr
                </span>
                <span className="w-5 inline-block ms-3">
                  <Flag code="tr" height={15} width={20} />
                </span>
              </button>
            </li>


            <li className="w-full">
              <button className="text-center btn ps-2 flex flex-row items-center w-full justify-between" onClick={() => { handleLanguageChange('ur') }}>
                <span>
                  ur
                </span>
                <span className=" inline-block">
                  <img src={ur} alt="ar" className="w-7 h-4 inline ms-2" />
                </span>
              </button>
            </li>

            <li className="w-full">
              <button className="text-center btn ps-2 flex flex-row items-center w-full justify-between" onClick={() => { handleLanguageChange('fa') }}>
                <span>
                  fa
                </span>
                <span className="w-5 inline-block ms-3">
                  <Flag code="ir" height={15} width={20} />
                </span>
              </button>
            </li>


            <li className="w-full">
              <button className="text-center ps-2 btn" onClick={() => { handleLanguageChange('hi') }}>
                <span>
                  hi
                </span>
                <span className="w-5 inline-block ms-3">
                  <Flag code="IN" height={15} width={20} />
                </span>
              </button>
            </li>

          </ul>
        </div>

        <CategorySelect categories={categories} onCategorySelect={handleNavigation} cat={cat} setCat={setCat} />

      </nav>

    </header>

  )
}