import { configureStore } from '@reduxjs/toolkit'
import api from '../slice/api'
import cartSlice from '../slice/cart';
import totalPrice from '../slice/totalPrice';
import settings from '../slice/settings';
import orderDetails from "../slice/orderDetails"

export const store = configureStore({
  reducer: {
   cart: cartSlice,
   apiBase: api,
   totalprice :totalPrice,
   settings:settings,
   orderdetails:orderDetails
   
  },
})