import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { clearCart, fetchCartContent, removeProduct } from "../../redux/slice/cart";
import { fetchTotalPrice } from "../../redux/slice/totalPrice";
import moment from 'moment'
// 
export default function Cart() {
  const [t, i18n] = useTranslation();

  const apiBase = useSelector((state) => state.apiBase.value);
  const settings = useSelector((state) => state.settings.data);

  const [tables, setTables] = useState([]);
  const cartData = useSelector((state) => state.cart.data);
  const totalprice = useSelector((state) => state.totalprice.data);
  const [dinein, setdinein] = useState(false);
  const [orderlater, setorderlater] = useState(false);

  const dispatch = useDispatch();

  // -------------get tables------------------

  useEffect(() => {
    axios
      .get(`${apiBase}get-dining-tables`)
      .then((response) => {
        setTables(response?.data.data);
      })
      .catch(console.error());
  }, [apiBase]);
  // -------------get tables------------------

  useEffect(() => {
    dispatch(fetchCartContent());
    dispatch(fetchTotalPrice());
  }, [dispatch]);

  const handleRemoveProduct = async (id) => {
    try {
      await dispatch(removeProduct(id));
      await dispatch(fetchCartContent());
      dispatch(fetchTotalPrice());
    } catch (error) {
      console.error(error);
    }
  };

  const today = new Date().toISOString().split("T")[0];
  const [inputValue, setinputValue] = useState({
    customer_name: "",
    address: "",
    sales_note: "",
    delivery_type: "home_delivery",
    time: moment().format('HH:mm'),
    table_no: tables[0]?.id,
    date: today,
    order_type: "order_later"
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setinputValue((prev) => {
      const updatedInputValue = {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };

      // Check if the selected delivery_type is "dining in" to show the select box
      if (updatedInputValue.delivery_type === "dining in") {
        setdinein(true);
      } else {
        setdinein(false);
      }

      return updatedInputValue;
    });
  };

  const handleOrder = () => {

    const { customer_name, address, sales_note, delivery_type, date, time, table_no, order_type } =
      inputValue;

    let queryParams = []
    if (dinein) {
      queryParams = [
        `customer_name=${customer_name}`,
        `address=${address}`,
        `sales_note=${sales_note}`,
        `delivery_type=${delivery_type}`,
        `date=${date}`,
        `time=${time}`,
        `table_no=${table_no}`,
        `order_type=${order_type}`
      ].join("&");
    } else {
      queryParams = [
        `customer_name=${customer_name}`,
        `address=${address}`,
        `sales_note=${sales_note}`,
        `delivery_type=${delivery_type}`,
        `date=${date}`,
        `time=${time}`,
        `order_type=${order_type}`
      ].join("&");
    }

    try {
      const userId = localStorage.getItem('menu_user_id')
      axios.get(`${apiBase}order-store?${queryParams}&user_id=${userId}`)
        .then((response) => {
          const singleProduct = response.data.order.order_details.map((product) => { return product.product.name })
          const quantity = response.data.order.order_details.map((product) => { return product.quantity })
          const price = response.data.order.order_details.map((product) => { return product.price })
          const sub_total = response.data.order.order_details.map((product) => { return product.sub_total })

          let whatsappMessageBody = "";
          for (let i = 0; i < singleProduct.length; i++) {
            whatsappMessageBody = whatsappMessageBody + `ürün : ${singleProduct[i]},%0a
miktar: ${quantity[i].substring(0, quantity[i]?.length - 2)},%0a
fiyat: ${price[i].substring(0, price[i]?.length - 2)},%0a
ara toplam: ${sub_total[i].substring(0, sub_total[i]?.length - 2)},%0a
          --------------%0a`
          }

          const tableName = tables.filter((table) => table.id === +response.data.order.table_no)
          const table = tableName[0]?.name
          const name = table?.replace("#", "%23")

          let whatsappMessageFooter = `adres: ${response.data.order.address},%0a
ad: ${response.data.order.customer_name},%0a
teslimat türü : ${response.data.order.delivery_type},%0a
${name ? `masa numarası : ${name},%0a` : ""}
tarih  : ${response.data.order.date},%0a
zaman : ${response.data.order.time},%0a
toplam fiyats : ${response.data.order.final_total.substring(0, response.data.order.final_total?.length - 2)}`;

          const whatsappMessage = whatsappMessageBody + whatsappMessageFooter



          const recipientNumber = settings.whatsapp;
          // Construct the WhatsApp link
          const whatsappLink = `https://wa.me/${recipientNumber}?text=${whatsappMessage}`;

          // Redirect to WhatsApp link
          window.location.href = whatsappLink;


          const userId = localStorage.getItem('menu_user_id')
          return axios.get(`${apiBase}clear-cart?user_id=${userId}`);
        })
        .then((res) => {
          setinputValue((prev) => ({
            ...prev, customer_name: "", address: "", sales_note: "", delivery_type: "home_delivery"
          }))
        })
        .catch((err) => {
          // handle err
          console.log(err);
        });

      // const response = await axios.get(url)

    } catch (error) {
      console.error(error);
    }
  };

  // ----------------------quantity--------------------------------
  const [quantities, setQuantities] = useState([]);

  useEffect(() => {
    const initialQuantities = cartData.map((data) => Math.floor(data.quantity));
    setQuantities(initialQuantities);
  }, [cartData]);

  let isFetchTotalPricePending = false;

  const updateProductQuantity = async (productId, newQuantity) => {
    try {
      const userId = localStorage.getItem('menu_user_id')
      await axios.get(
        `${apiBase}update-product-quantity/${productId}/${newQuantity}?user_id=${userId}`
      )

      if (!isFetchTotalPricePending) {
        isFetchTotalPricePending = true;
        setTimeout(async () => {
          try {
            await dispatch(fetchTotalPrice());
          } catch (error) {
            // Handle errors from fetchTotalPrice, including 429
            console.error(error);
          }
          isFetchTotalPricePending = false;
        }, 1000); // Throttle to 1 second
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleQuantityChange = (index, newQuantity) => {
    const parsedQuantity = parseInt(newQuantity);
    if (!isNaN(parsedQuantity) && parsedQuantity >= 1) {
      const updatedQuantities = [...quantities];
      updatedQuantities[index] = parsedQuantity;
      setQuantities(updatedQuantities);

      const productId = cartData[index]?.id;
      if (productId) {
        updateProductQuantity(productId, +newQuantity);
      }
    }
  };

  const decrement = async (product_id, index) => {
    if (quantities[index] > 1) {
      const updatedQuantities = [...quantities];
      updatedQuantities[index] -= 1;
      setQuantities(updatedQuantities);

      const newQuantity = updatedQuantities[index]
      updateProductQuantity(product_id, parseInt(`${newQuantity}`));
      // await axios.get(
      //   `${apiBase}update-product-quantity/${variation_id}/${newQuantity}`
      // )
      // dispatch(fetchCartContent());
      // dispatch(fetchTotalPrice());

    }
  };

  const increment = async (product_id, index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] += 1;
    setQuantities(updatedQuantities);

    const newQuantity = updatedQuantities[index]
    updateProductQuantity(product_id, parseInt(`${newQuantity}`));

    // await axios.get(`${apiBase}add-to-cart/${variation_id}`)

    // dispatch(fetchCartContent());
    // dispatch(fetchTotalPrice());
  };
  // ----------------------quantity--------------------------------

  // Function to toggle between "order_now" and "order_later"
  const toggleOrderType = () => {
    if (inputValue.order_type === 'order_now') {
      setorderlater(false);
    } else {
      setorderlater(true);
    }
    setinputValue(prevInputValue => ({
      ...prevInputValue,
      order_type: prevInputValue.order_type === 'order_now' ? 'order_later' : 'order_now',
    }));
  };
  return (
    <>
      <button
        type="button"
        className="bg-white  text-orange-600 w-[120px] h-16 rounded-t-full text-3xl bottom-0 fixed cart"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <div className="relative">
          <FontAwesomeIcon icon={faCartShopping} />
          <div className="absolute top-0 w-5 text-xs text-white rounded left-16 opacity-70 bg-slate-800">
            {quantities.reduce((rec, acc) => rec + acc, 0)}
          </div>
        </div>
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("cart")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="p-1 modal-body">
              <div className="container-fluid">
                {/* <div className="row">
            <div className="font-semibold text-center col-3">{t("name")}</div>
            <div className="font-semibold text-center col-3">{t("price")}</div>
            <div className="font-semibold text-center col-3">{t("quantity")}</div>
            <div className="font-semibold text-center col-3">{t("remove")}</div>
            </div> */}

                {cartData.map((data, index) => (
                  <>
                    {/* Product name */}
                    <div className="text-center ">{data?.product.name}</div>

                    <div className="text-center row mb-3" key={data.product.id}>
                      {/* product price */}
                      <div className="text-black col-4">{data?.price}</div>
                      {/* Quantity input */}
                      <div className="flex justify-between p-0 text-center col-5">
                        <div className="shadow">
                          <button
                            className="quantity-input__modifier--left"
                            onClick={() => decrement(data.id, index)}
                          >
                            {/* &mdash; */}-
                          </button>
                          <input
                            className="w-10 text-center"
                            type="number"
                            name="quantities"
                            value={quantities[index]}
                            onChange={(e) =>
                              handleQuantityChange(index, e.target.value)
                            }
                          />
                          <button
                            className="quantity-input__modifier--right "
                            onClick={() => increment(data.id, index)}
                          >
                            &#xff0b;
                          </button>
                        </div>
                      </div>

                      {/* Remove button */}
                      <div className="text-red-600 col-3 ">
                        <button
                          className="px-2 bg-red-300 border-red-600 rounded-full border-1"
                          onClick={() => handleRemoveProduct(data?.id)}
                        >
                          <FontAwesomeIcon icon={faClose} />
                        </button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>


            <div className="modal-footer ">
              <p className="font-semibold">
                <span className=" me-1">{t("Total price")} : {totalprice.toFixed(2)}</span>
                {settings.currency_symbol}
              </p>
              <hr />
              <br />
            </div>

            <div>
              <div className="w-full">
                <form
                  className="px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md"
                >
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="username"
                    >
                      {t("name")}
                    </label>
                    <input
                      className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="username"
                      type="text"
                      name="customer_name"
                      value={inputValue.customer_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="address"
                    >
                      {t("address")}
                    </label>
                    <input
                      className="w-full px-3 py-2 mb-3 leading-tight text-gray-700 border border-red-500 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="address"
                      type="text"
                      name="address"
                      value={inputValue.address}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="sales_note"
                    >
                      {t("sales_note")}
                    </label>
                    <textarea
                      className="w-full px-3 py-2 mb-3 leading-tight text-gray-700 border border-red-500 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="sales_note"
                      name="sales_note"
                      value={inputValue.sales_note}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-6">
                    <label className="col-6 col-sm-4">
                      <input
                        type="radio"
                        name="delivery_type"
                        value="dining in"

                        checked={inputValue.delivery_type === "dining in"}
                        onChange={handleChange}
                      />
                      <span className="p-1">   {t("in restaurant")}</span>

                    </label>
                    <label className=" col-6 col-sm-4">
                      <input
                        type="radio"
                        name="delivery_type"
                        value="home_delivery"
                        className="px-2"
                        checked={inputValue.delivery_type === "home_delivery"}
                        onChange={handleChange}
                      />
                      <span className="p-1">  {t("delivary")}</span>
                    </label>
                    <label className="col-6 col-sm-4">
                      <input
                        type="radio"
                        name="delivery_type"
                        className="px-2"

                        value="i_will-pick_it_up_by_my_self"
                        checked={
                          inputValue.delivery_type ===
                          "i_will-pick_it_up_by_my_self"
                        }
                        onChange={handleChange}
                      />
                      <span className="p-1">  {t("pick up")}</span>
                    </label>
                  </div>

                  <div>
                    <span>{t("order later")}</span>
                    <label className="m-2 switch ">
                      <input type="checkbox" onChange={toggleOrderType} checked={inputValue.order_type === 'order_later'} />
                      <span className="slider round"></span>
                    </label>
                    <span>{t("order now")}</span>

                  </div>


                  {orderlater && <div className="flex justify-between mb-6">
                    <input
                      className="p-2 border-2 border-gray-400 rounded"
                      type="time"
                      name="time"
                      value={inputValue.time}
                      onChange={handleChange}
                    />
                    <label>
                      <input
                        type="date"
                        name="date"
                        className="p-2 border-2 border-gray-400 rounded "
                        value={inputValue.date}
                        onChange={handleChange}
                      />
                    </label>
                  </div>}


                  {dinein && (
                    <div className="mb-6">
                      <select
                        name="table_no"
                        id=""
                        onChange={handleChange}
                        value={inputValue.table_no}
                      >
                        <option>{t("dining tables")}</option>
                        {tables.map((tables) => (
                          <option key={tables.id} value={tables.id}>
                            {tables.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="flex items-center justify-between">
                    <button
                      className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline" type="button"
                      onClick={handleOrder}
                    >
                      {t("submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
