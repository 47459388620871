import { useEffect, useState } from "react";
import bg from "../../assets/img/pexels-pixabay-326333.jpg"
import Card from "../../components/shared/categoryCard"
import Header from "../../components/layouts/header";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import { fetchCartContent } from '../../redux/slice/cart';
import { fetchTotalPrice } from '../../redux/slice/totalPrice'
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
// 
export default function ProductDetails({ setCat, categories }) {

  const apiBase = useSelector((state) => state.apiBase.value);
  const settings = useSelector((state) => state.settings.data);

  const dispatch = useDispatch();

  const [t, i18n] = useTranslation()

  const [product, setproduct] = useState([])
  const [message, setmessage] = useState("")
  const [show, setshow] = useState("")
  const idparam = useParams().id
  const [id, setId] = useState()

  const image = product?.image ? product?.image : `${settings.image_url}/uploads/${settings.logo}`

  const cat = categories.filter((cat) => cat.id == product.product_class_id)

  useEffect(() => {
    setCat(cat[0]?.name)
  }, [cat[0]?.name])

  useEffect(() => {
    handleId()
  }, [product])
  const handleId = () => {
    setId(product.variations ? product.variations[0].id : product.product_class_id)
  }
  useEffect(() => {
    axios.get(`${apiBase}get-product/${idparam}`).then((response) => {
      setproduct(response?.data.data);

    }).catch(console.error())
  }, [apiBase]);

  const handleAddtocart = async (id) => {
    try {
      const userId = localStorage.getItem('menu_user_id')
      await axios.get(`${apiBase}add-to-cart/${id}?user_id=${userId}`);
      dispatch(fetchCartContent());
      dispatch(fetchTotalPrice())
      Swal.fire({
        title: `${product.name}`,
        text: `${t("added to cart")}`,
        icon: 'success',
        showConfirmButton: false,
        timer: 1000
      })
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', width: '100%', padding: '3vw', paddingBlock: '25vh', color: 'blue', backgroundColor: 'black' }} >
        <div className="container justify-center mt-5">

          <div className="container">
            <div className="justify-center cursor-pointer row">
              <div className="col-md-6">


                <img src={image} alt="" className='w-full h-[80%] aspect-ratio rounded-t border-1 border-white border-b-0' />
                <div className='px-4 py-2 text-black bg-white border-t-8 border-red-800 rounded-b '>
                  <h5 className='flex justify-between p-0 '>
                    {product?.name}

                    {product?.variations?.length > 1 &&
                      <button type="button" className="" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                        size <br />   <FontAwesomeIcon icon={faList} /></button>
                    }

                  </h5>

                  <p dangerouslySetInnerHTML={{ __html: product?.product_details }} />
                  <p className='flex justify-between p-0 m-0'>
                    <button className='px-1 text-white bg-red-800 rounded-full' onClick={() => handleAddtocart(id)}> <FontAwesomeIcon icon={faPlus} /></button>
                    {product?.sell_price?.substring(0, product?.sell_price?.length - 2)} {settings.currency_symbol} </p>
                </div>
              </div>
              {show && <div className='absolute py-10 mx-auto text-center bg-white rounded shadow col-10 col-sm-6 shshadow-slate-600'>
                <h3 className='text-black'>{message}</h3>
                <button className='font-mono font-bold rounded-full btn-primary btn ' onClick={() => setshow(false)}>X</button>
              </div>}

            </div>
          </div>
        </div>
      </div>


      <div className="modal text-black fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{t("variations")}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="font-semibold col-6">{t("size")}</div>
                  <div className="font-semibold col-6">{t("name")}</div>
                </div>
                {product?.variations?.map((item) =>
                  <div className="my-4 row">
                    <div className="font-semibold text-gray-700 col-4">{item.size.name}</div>
                    <div className="col-4">{item.name}</div>
                    <div className="col-2"></div>
                    <button className='px-1 text-white bg-red-800 rounded-full col-2' onClick={() => handleAddtocart(item.id)}> <FontAwesomeIcon icon={faPlus} /></button>
                  </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}