import { useEffect, useState } from "react";
import bg from "../../assets/img/pexels-pixabay-326333.jpg"
import Card from "../../components/shared/categoryCard"
import axios from "axios";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";


export default function Home({ cat, setCat }) {

    const apiBase = useSelector((state) => state.apiBase.value);
    const [ctgrs, setCtgrs] = useState([])
    const [t, i18n] = useTranslation()

    useEffect(() => {
        setCat(t("categories"))
    }, [])

    useEffect(() => {
        axios.get(`${apiBase}categories-list`).then((response) => {
            setCtgrs(response?.data.data);
        }).catch(console.error())
    }, [apiBase]);
    return (
        <>
            <div style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'no-repeat', width: '100%', height: 'fit-content', color: 'blue', backgroundColor: 'black' }}
                className="pt-10 px-3 lg:pt-16 lg:px-12">
                <div className=" row w-full">
                    {ctgrs?.map(catgegory => (
                        <Card key={catgegory.id} categories={catgegory} cat={cat} setCat={setCat} />
                    ))}
                </div>
            </div>
        </>
    )
}