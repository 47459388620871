import Header from "./components/layouts/header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect } from 'react';
import "./i18n";
import Home from "./pages/home/home";
import Footer from "./components/layouts/footer";
import { useState } from "react";
import Products from "./pages/products/products";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Cart from "./components/layouts/cart";
import { fetchSettings } from "./redux/slice/settings";
import ProductDetails from "./pages/products/productDetails";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";




function App() {
  const apiBase = useSelector((state) => state.apiBase.value);
  const settings = useSelector((state) => state.settings.data);
  const [t, i18n] = useTranslation()

  const [cat, setCat] = useState(t("categories"))
  // 
  const [ctgrs, setCtgrs] = useState([])

  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(fetchSettings())
    window.changeFavicon(`${settings.image_url}/uploads/${settings.logo}`);
    axios.get(`${apiBase}categories-list`).then((response) => {
      setCtgrs(response?.data.data);
    }).catch(console.error())
  }, [apiBase]);

  useEffect(() => {
    const userId = localStorage.getItem('menu_user_id')
    if (!userId) {
      axios.get(`${apiBase}get-user-id`)
        .then((response) => {
          localStorage.setItem('menu_user_id', response?.data?.data)
        }).catch(console.error())
    }
  })
  // 

  return (
    <div className="relative App">

      <Helmet>
        <meta charSet="utf-8" />
        <title>{settings.site_title}</title>
        <link rel="icon" href={`${settings.image_url}/uploads/${settings.logo}`} />
      </Helmet>

      <BrowserRouter>
        <Header categories={ctgrs} photos={settings} cat={cat} setCat={setCat} />
        <div className="w-full  md:py-1 py-[11vh]"></div>

        <Routes>
          <Route path="/" element={<Home cat={cat} setCat={setCat} />} />
          <Route path="/en" element={<Home cat={cat} setCat={setCat} />} />
          <Route path="/ar" element={<Home cat={cat} setCat={setCat} />} />
          <Route path="/fa" element={<Home cat={cat} setCat={setCat} />} />
          <Route path="/fr" element={<Home cat={cat} setCat={setCat} />} />
          <Route path="/hi" element={<Home cat={cat} setCat={setCat} />} />
          <Route path="/nl" element={<Home cat={cat} setCat={setCat} />} />
          <Route path="/tr" element={<Home cat={cat} setCat={setCat} />} />
          <Route path="/ur" element={<Home cat={cat} setCat={setCat} />} />

          <Route path="/products/:id" element={<Products setCat={setCat} categories={ctgrs} />} />

          <Route path="/productdetails/:id" element={<ProductDetails setCat={setCat} categories={ctgrs} />} />
        </Routes>

        <Cart />

        <Footer data={settings} />
      </BrowserRouter>


    </div>
  );
}

export default App;
